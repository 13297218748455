import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import PlayerApi from '@/support/playerApi';
import PlayerUtil from '@/support/playerUtil';
import { ServerDataModule } from '@/store/serverData';
import Util from '@/support/utility';

@Component
export default class ChangeCreationDateComponent extends Vue {

    get serverData() {
        return ServerDataModule.serverData;
    }

    video: SUR.VideoDto = null;
    creationDate: string = null;

    errors: string[] = [];

    busy = false;
    saveDisabled: boolean = true;
    key = 'changeCreationDate';

    options = {
    };

    @Prop({ default: <any>300 })
    height: string;

    @Watch('creationDate', { immediate: true })
    onStudioChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    updateButtons() {
        if (!this.creationDate || !this.creationDate.trim())
            this.saveDisabled = true;
        else
            this.saveDisabled = false;
    }

    created() {
        Debug.setDebugModule('ChangeCreationDate', this);
    }

    mounted() {
    }

    showExpired() {
        this.done();
    }

    show(video: SUR.VideoDto) {
        this.busy = false;
        this.saveDisabled = false;
        this.video = video;
        this.creationDate = Util.formatDate(this.video.creationDate);

        (<any>this.$modal).show(this.key);
    }

    hide() {
        (<any>this.$modal).hide(this.key);
    }

    validateInput() {
        this.errors.splice(0, this.errors.length);

        if (!this.creationDate)
            this.errors.push('Friendly name required');
        //else if (this.creationDate != Util.sanitizeSentence(this.creationDate)) // TODO: validate date
        //    this.errors.push('Invalid date.');

        return !this.errors.length;
    }

    async done() {
        if (!this.validateInput()) return false;

        this.saveDisabled = true;
        this.busy = true;

        let video = Object.assign({}, this.video);
        video.creationDate = this.creationDate;

        try {
            let result = await PlayerApi.changeVideoCreationDate(video);

            this.video.creationDate = result.creationDate;

            Debug.log('changeCreationDate done SUCCEEDED');
            Util.showToast('Changed creation date');

            this.hide();

            return null;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("changeCreationDate FAILED", this.creationDate, this.video, message);
            Util.showToast('Failed to change creation date' + ' ' + message, true);
            this.busy = false;
            this.saveDisabled = false;
            return null;
        } finally {

        }
    }

    cancel() {
        this.hide();
    }

}
